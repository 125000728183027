header {
  background-color: $primary;
  position: sticky;
  top: 0;
  padding: 1rem 0;
  width: 100%;
  z-index: 1977;
}

.fixedBar {
  background-color: $primary;

  .header {
    transition: all 200ms ease-in-out;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  &__nav {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  &__logo {
    transition: all 200ms ease-in-out;

    img {
      margin: 1rem 0 1rem;
      max-height: 80px;
      width: unset;
    }
  }
}