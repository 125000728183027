@font-face {
  font-family: 'Gotham-Book';
  src: url('../../../fonts/Gotham-Book.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('../../../fonts/Gotham-Medium.woff2') format('woff2');
  font-display: swap;
  font-weight: bold;
}