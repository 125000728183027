.main-news {

  &__row {
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;

    @include media-breakpoint-up(md){
      margin-top: 0;
    }
  }

  &__background{
    background-color: $primary;
    height: 10vh;
    width: 57.5%;
    right: 0;
    position: absolute;
  }

  &__img {

    img {
      max-width: 100%;
      height: auto;
      z-index: 10;
      position: relative;
    }
  }
}