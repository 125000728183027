@import "mmMenu";

.nav {
  transition: height 200ms ease-out;
  position: relative;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  .active {
    color: $primary;
    background-color: $white;
  }

  &__mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__item {
    list-style: none;
    font-family: $headings-font-family;
    font-size: 1.2rem;

    & + .nav__item {
      padding-left: 1rem;
    }

    &:hover {
      color: $primary;
      background-color: $white;
    }

    &_label {
      cursor: pointer;
    }

    input {
      display: none;
    }

    input:checked + .nav__lvl2 {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      background: $white;
      padding: 1rem;
      z-index: 100;
    }
    
    a {
      color: $black;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

      &:hover {
        color: $primary;
        background-color: $white;
      }
    }
  }

  &__lvl2 {
    display: none;

    .nav__item {
      padding: 0;
    }
  }
}

.fixedBar .nav {
  height: 80px;

  @include media-breakpoint-up(xl){
    height: 90px;
  }
}