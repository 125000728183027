$white:           #fbfbfb;
$black:           #000;
$black-dirty:     #313131;
$light-gray:      #f6f6f6;

$primary:         #00a1e7;
$secondary:       #fff;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-900 !default;


//$enable-caret:                true !default;
$enable-rounded:                false;
//$enable-shadows:              false !default;
//$enable-gradients:            false !default;
//$enable-transitions:          true !default;
//$enable-reduced-motion:       true !default;
//$enable-smooth-scroll:        true !default;
//$enable-grid-classes:         true !default;
//$enable-button-pointers:      true !default;
//$enable-rfs:                  true !default;
//$enable-validation-icons:     true !default;
//$enable-negative-margins:     false !default;
//$enable-deprecation-messages: true !default;
//$enable-important-utilities:  true !default;

$body-bg:                     $light-gray !default;
$body-color:                  $black-dirty;
//$body-text-align:           null !default

//$link-color:                $primary !default;
$link-decoration:             none;
//$link-shade-percentage:     20% !default;
//$link-hover-color:          shift-color($link-color, $link-shade-percentage) !default;
//$link-hover-decoration:     null !default;

$font-family-sans-serif: 'Gotham-Book', 'Arial', sans-serif;
$font-size-base:          1.2rem;
$headings-font-family:    'Gotham-Medium', 'Arial', sans-serif;

/*
* button styles
*/

//$btn-padding-y:               $input-btn-padding-y !default;
//$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:               $headings-font-family;
//$btn-font-size:               ;
//$btn-line-height:             $input-btn-line-height !default;

$icon-path: '/content/themes/flasco_2021/assets/dist/icons/';


/*
* input styles
*/

//$input-btn-font-family:         $headings-font-family;
//$input-color:                   $white;
//$input-bg:                      transparent;
//$input-border-color:            $primary;
//$input-border-width:            2px;
//$form-select-indicator-color:   $primary;
//$input-focus-border-color:      $white;
//$input-focus-box-shadow:        none;
//
//$input-select-option-bg:        $black;
//$input-select-option-color:     $white;



//$form-select-indicator:         url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'><path fill=\'none\' stroke=\'#{$form-select-indicator-color}\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M2 5l6 6 6-6\'/></svg>') !default;
//$form-select-indicator-padding: $form-select-padding-x * 3 !default; // Extra padding for background-image