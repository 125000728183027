.btn {

  text-transform: uppercase;


  &-primary {
    color: $white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.6);

    :hover {
      padding: 0.5rem;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.6);
    }
  }

  &-outline {

    &-secondary {
      border: 2px solid $white;

      &:hover {
        background-color: $white;
        color: $primary;
      }
    }
  }
}