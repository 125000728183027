.key-visual {

  padding: 3.5rem 0 7rem;
  background-image: linear-gradient(to bottom, #00a1e7 200%, #007de7);

  &__text-container *{
    color: $white;
    font-size: 2.5rem;
    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }
  }
}