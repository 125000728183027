.footer {
  font-size: 1rem;

  ul {
    margin: 0;
    padding: 0;
  }

  a {
    color: $black-dirty;
  }

  li {
    list-style: none;
  }

  &__links {
    min-height: 3rem;
    align-items: center;
  }

  &__headline {
    font-size: $font-size-base;
  }

  &__container {
    max-width: 1320px;
    margin: auto;
  }

  &__visual-container {
    position: relative;
    color: $black-dirty;
    font-size: 2.5rem;
    line-height: 1.1;
    text-align: end;
    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }
  }

  &__claim {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }
}
